import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Link, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				STARGAZE EVENTS
			</title>
			<meta name={"description"} content={"Добро пожаловать на мероприятия StarGaze"} />
			<meta property={"og:title"} content={"STARGAZE EVENTS"} />
			<meta property={"og:description"} content={"Добро пожаловать на мероприятия StarGaze"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header2>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://images.unsplash.com/photo-1608280204863-2d7a1ed5253e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					object-fit="contain"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="100%"
					max-width="100%"
					sm-min-height="100vw"
					srcSet="https://images.unsplash.com/photo-1608280204863-2d7a1ed5253e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1608280204863-2d7a1ed5253e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1608280204863-2d7a1ed5253e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1608280204863-2d7a1ed5253e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1608280204863-2d7a1ed5253e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1608280204863-2d7a1ed5253e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1608280204863-2d7a1ed5253e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Добро пожаловать на мероприятия StarGaze
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Добро пожаловать на StarGaze Events, где мы воплощаем ваши мечты в реальность. Мы специализируемся на создании незабываемых впечатлений, планируете ли вы свадьбу, корпоративное мероприятие, день рождения или любое другое особое событие. Наша команда профессиональных организаторов и координаторов мероприятий постарается сделать ваше мероприятие по-настоящему уникальным и запоминающимся.
				</Text>
				<Link
					href="/services"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Наши сервисы
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Кто мы
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					В StarGaze Events мы верим, что каждое мероприятие — это возможность оставить незабываемые воспоминания. Имея многолетний опыт работы в сфере организации мероприятий, мы обладаем знаниями для управления всеми аспектами вашего мероприятия, от концепции до реализации. Наше внимание к деталям, креативность и стремление к совершенству отличают нас от конкурентов. Мы тесно сотрудничаем с вами, чтобы понять ваше видение и воплотить его в жизнь, следя за тем, чтобы каждая деталь была идеальной.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Почему выбирают нас?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Мы понимаем, что планирование мероприятия может быть стрессовым, поэтому предлагаем полный спектр услуг, чтобы сделать процесс максимально комфортным. Наша команда имеет опыт управления всеми типами мероприятий, больших и малых, и мы гордимся своей способностью каждый раз добиваться исключительных результатов.{"\n"}
					<br />
					<Strong>
						<br />
						{"\n"}Вот несколько причин выбрать мероприятия StarGaze:
					</Strong>
					{"\n"}Персонализированное обслуживание: мы уделяем время тому, чтобы понять ваши уникальные потребности и предпочтения, создавая индивидуальный план, отражающий ваше видение.{"\n"}
					<br />
					Экспертная координация:
Наши опытные координаторы берут на себя всю логистику, обеспечивая бесперебойное проведение вашего мероприятия от начала до конца.{"\n"}
					<br />
					Креативные решения: мы предлагаем новые идеи и инновационные решения, которые сделают ваше мероприятие особенным.
					<br />
					{"\n"}Внимание к деталям: для нас нет мелочей. Мы тщательно планируем каждый аспект вашего мероприятия, чтобы обеспечить его совершенство.{"\n"}
					<br />
					Планирование без стресса: мы снимаем стресс с планирования мероприятия, позволяя вам расслабиться и насладиться своим особенным днем.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				align-self="center"
				height="100%"
				align-items="center"
				justify-content="center"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text" font="--lead">
				<Strong>
					ДОПОЛНИТЕЛЬНЫЕ УСЛУГИ{"\n"}
					<br />
				</Strong>
				ПОМИМО НАШИХ СТАНДАРТНЫХ УСЛУГ ПО ПЛАНИРОВАНИЮ МЕРОПРИЯТИЙ, МЫ ПРЕДЛАГАЕМ РЯД ДОПОЛНИТЕЛЬНЫХ УСЛУГ ДЛЯ УЛУЧШЕНИЯ ВАШЕГО МЕРОПРИЯТИЯ. ОТ ИНДИВИДУАЛЬНЫХ УКРАШЕНИЙ ДО ЦВЕТОЧНЫХ КОМПОЗИЦИЙ, ОТ РАЗВЛЕЧЕНИЙ ДО ПИТАНИЯ — У НАС ЕСТЬ ВСЕ, ЧТОБЫ СДЕЛАТЬ ВАШЕ МЕРОПРИЯТИЕ ПО-настоящему ОСОБЕННЫМ. НАША КОМАНДА РАБОТАЕТ С СЕТЬЮ ПРОВЕРЕННЫХ ПОСТАВЩИКОВ, ЧТОБЫ ПРЕДОСТАВИТЬ ВАМ УСЛУГИ И ПРОДУКЦИЮ ВЫСОКОГО КАЧЕСТВА.
			</Override>
		</Components.Cta>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="text6" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});